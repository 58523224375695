<template>
    <LiefengContent>
        <template v-slot:title>{{`${$route.query.orgName || ''}网上家园建设情况总览`}}</template>
        <template v-slot:toolsbarRight>
            <Button type="error" @click="$router.push('/linehomeindex')">返回</Button>
        </template>
         <template v-slot:contentArea>
             <div class="container-div">
                 <div class="top-div"></div>
             </div>
        </template>
       
    </LiefengContent>
    
</template>

<script>
import LiefengContent from "@/components/LiefengContent";
export default {
    components:{LiefengContent},
    data(){
        return{
           
        }
    },
     created(){
       
       
    },
    methods:{
    }
    
}
</script>

<style lang="less" scoped>
 //编写css
.container-div{
    .top-div{}
}

</style>